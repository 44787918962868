import React, { useState } from 'react'
import { connect } from 'react-redux'
import LoadingModal from '../modal/LoadingModal';
import InputPrecioPanel from '../InputPrecioPanel';
import Labelmoney from "../../components/labelmoney";

export const ReporteCorteDia = () => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({});

  return (
    < >
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      <div className='flex flex-col gap-4'>
        <div className="justify-center flex gap-2 items-center">
            <label className="font-semibold text-size ">Fecha:</label>
            <input 
            type="date" 
            min={'2023-01-01'} 
            className="border-form" 
            />
        </div>
        <div className="grid grid-rows-2 grid-cols-3 gap-2 w-1/2 bg-sky-100 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">DINERO:</label>
          <div className="flex gap-2 items-center col-span-2">
            <label className="font-bold text-size row-span-2 w-full">INICIO EL DIA EN CAJA:</label>
            <InputPrecioPanel
            className="bg-white w-36"
            form={true}
            min={0}
            newValor={0}
            placeholder="limite de Credito"
            setValor={(valor) => { 
              //setValue('limiteCredito',moneyToValue(valor));
            }}
            />
          </div>
          <div className="flex gap-2 items-center col-span-2">
            <label className="font-bold text-size row-span-2 w-full">INICIO EL DIA EN BANCOS:</label>
            <InputPrecioPanel
            className="bg-white w-36"
            form={true}
            min={0}
            newValor={0}
            placeholder="limite de Credito"
            setValor={(valor) => { 
              //setValue('limiteCredito',moneyToValue(valor));
            }}
            />
          </div>
        </div>
        
        <div className="grid grid-flow-row grid-cols-3 gap-2 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-6 flex items-center justify-center">VENTAS:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">TOTAL:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">FR:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">TARJETA:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">DPB,TRANS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">CREDITO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 bg-sky-100 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">COBRANZA:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">AROTHER IN:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 bg-sky-100 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">AROTHER OUT:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">PAGOS AP:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 bg-sky-100 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">PRESTAMOS DE AP:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">GASTOS (EX):</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-3 gap-2 bg-sky-100 w-1/2 lg:w-1/3">
          <label className="font-bold text-size row-span-2 flex items-center justify-center">INVERSION DE EQUIPO:</label>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">EFECTIVO:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
          <div className="flex gap-2 items-center col-span-2 ">
            <label className="font-bold text-size row-span-2 w-full">BANCOS:</label>
            <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
          </div>
        </div>

      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteCorteDia)